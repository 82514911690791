import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { client } from '../api/client';
import { domainsEntity } from '../schemas';
import { Domain, Pagination } from '../interfaces';
import { RootState } from '.';
import { paginate, safeArray } from '../utils';
import { fetchProviders } from './provider.slice';

export const createDomain = createAsyncThunk('domains/create', async domain => {
  const response = await client.post('/domains', {
    domain
  });
  const normalized = normalize(response.data.data, domainsEntity);

  return normalized.entities;
});

export const fetchDomain = createAsyncThunk(
  'domains/single',
  async (id: string) => {
    const response = await client.get(`/domains/${id}`);
    const normalized = normalize(response.data.data, domainsEntity);

    return normalized.entities;
  }
);

export const fetchDomainWithGroups = createAsyncThunk(
  'domains/single',
  async (id: string) => {
    const response = await client.get(`/domains/${id}?preload_groups=true`);
    const normalized = normalize(response.data.data, domainsEntity);

    return normalized.entities;
  }
);

export const fetchPortalDomain = createAsyncThunk(
  'domains/single',
  async (id: string) => {
    const response = await client.get(`/portal_domains/${id}`);
    const normalized = normalize(response.data.data, domainsEntity);

    return normalized.entities;
  }
);

export const fetchPortalDomainWithGroups = createAsyncThunk(
  'domains/single',
  async (id: string) => {
    const response = await client.get(
      `/portal_domains/${id}?preload_groups=true&portal_visibility=true`
    );
    const normalized = normalize(response.data.data, domainsEntity);

    return normalized.entities;
  }
);

export const fetchMapDomains = createAsyncThunk(
  'domains/all',
  async (filter: Pagination) => {
    const response = await client.get('/map_domains', filter);
    const normalized = normalize(response.data.data, [domainsEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const fetchDomains = createAsyncThunk(
  'domains/all',
  async (filter: Pagination) => {
    const response = await client.get('/domains', filter);
    const normalized = normalize(response.data.data, [domainsEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const fetchDomainsWithGroups = createAsyncThunk(
  'domains/all',
  async (filter: Pagination) => {
    const response = await client.get('/domains?preload_groups=true', filter);
    const normalized = normalize(response.data.data, [domainsEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const fetchPortalDomains = createAsyncThunk(
  'domains/all',
  async (filter: Pagination) => {
    const response = await client.get(
      '/domains?portal_visibility=true',
      filter
    );
    const normalized = normalize(response.data.data, [domainsEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

// TODO: Add pagination parameter.
export const fetchDomainsByBoundary = createAsyncThunk(
  'domains/all',
  async (boundary: String) => {
    const response = await client.get(`/domains?boundary=${boundary}`, {
      page: 0,
      page_size: 10000
    });
    const normalized = normalize(response.data.data, [domainsEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const updateDomain = createAsyncThunk(
  'domains/update',
  async ({ id, domain }: { id: string; domain: Domain }) => {
    const response = await client.put(`/domains/${id}`, {
      domain
    });
    const normalized = normalize(response.data.data, domainsEntity);

    return normalized.entities;
  }
);

export const archiveDomain = createAsyncThunk(
  'domains/archive',
  async (id: number) => {
    await client.put(`/archive/domains/${id}`);
    return id;
  }
);

export const unarchiveDomain = createAsyncThunk(
  'domains/unarchive',
  async (id: number) => {
    await client.put(`/unarchive/domains/${id}`);
    return id;
  }
);

export const deleteDomain = createAsyncThunk(
  'domains/delete',
  async (id: number) => {
    await client.delete(`/domains/${id}`);
    return id;
  }
);

const domainAdapter = createEntityAdapter<Domain>();
const initialState = domainAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const domainSlice = createSlice({
  name: 'domains',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      domainAdapter.upsertMany(state, safeArray(payload.domains.entities));
      state.isLoading = false;
      state.pagination = payload.domains.pagination;
    });
    builder.addCase(fetchDomains.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchDomains.fulfilled, (state, { payload }) => {
      domainAdapter.upsertMany(state, safeArray(payload.domains));
      state.isLoading = false;
      state.pagination = payload.pagination;
    });
    builder.addCase(fetchDomains.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchDomain.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchDomain.fulfilled, (state, { payload }) => {
      domainAdapter.upsertMany(state, safeArray(payload.domains));
      state.isLoading = false;
    });
    builder.addCase(fetchDomain.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(createDomain.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createDomain.fulfilled, (state, { payload }) => {
      domainAdapter.upsertMany(state, safeArray(payload.domains));
      state.isLoading = false;
    });
    builder.addCase(createDomain.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updateDomain.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateDomain.fulfilled, (state, { payload }) => {
      domainAdapter.upsertMany(state, safeArray(payload.domains));
      state.isLoading = false;
    });
    builder.addCase(updateDomain.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteDomain.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteDomain.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteDomain.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchProviders.fulfilled, (state, { payload }) => {
      domainAdapter.upsertMany(state, safeArray(payload.domains));
    });
  }
});

export const domainSelectors = domainAdapter.getSelectors<RootState>(
  state => state.domains
);

export default domainSlice.reducer;
